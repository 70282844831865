import { db } from '@/config/firebase';

async function getAssetsByCode(assetCodes) {
    let dbRef = [];

    for (const assetCode of assetCodes) {
        let assetRef = db.collection('assets').doc(assetCode).get();
        dbRef.push(assetRef);
    }

    return Promise.all(dbRef).then(docs => {
        let assetsObj = {};
        docs.forEach(doc => {
            let asset = doc.data();
            if (!_.isEmpty(asset)) {
                assetsObj[asset.assetCode] = asset;
            }
        });

        return Promise.all([assetsObj]);
    });
}


export default {
    getAssetsByCode,
}