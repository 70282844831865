<template>
	<div class="animated fadeIn">
		<b-card title="Dispatch Importer" sub-title="Manages the import of single dispatch">
			<b-container class="mt-4">
				<b-form @submit.stop.prevent="handleSubmit" novalidate>
					<b-container fluid>
						<div v-show="!importOngoing && !importDone">
							<b-row class="my-12">
								<b-col sm="6">
									<b-form-group label="Dispatch Form:"
										description="Please select a valid json file for dispatch format.">
										<b-form-file v-model="file" ref="fileinput" @change="loadTextFromFile"
											:state="Boolean(file)" placeholder="Choose a JSON file"></b-form-file>
									</b-form-group>
									<label>
										Download sample template
										<a :href="templateUrl" download="Dispatch Importer.json">here.</a>
									</label>
								</b-col>
								<b-col sm="1">
									<b-button variant="primary" class="resetButton"
										@click="startAnotherImport">Reset</b-button>
								</b-col>
								<b-col sm="12">
									<b-card v-show="!importOngoing && loadedTextFromFile.length > 0" title="Content Preview"
										sub-title="Below is the overview of the content of the dispatch form you have selected">
										<br />
										<json-viewer :value="jsonData" />
										<br />
										<b-button variant="primary" @click="importFile">Proceed with Import</b-button>
									</b-card>
								</b-col>
							</b-row>
						</div>

						<div v-show="importOngoing || importDone">
							<b-row class="my-12">
								<b-col sm="12">
									<b-card title="Import Status" sub-title>
										<p class="card-text">
											{{ importStatusDisplay }}
										</p>
										<p class="card-text container">
											<loading :active.sync="importOngoing" loader="spinner" color="#20A8D8"
												:is-full-page="false" />
										</p>
										<span v-show="!importOngoing">
											<b-button variant="primary" @click="startAnotherImport">Start Another
												Import</b-button>
										</span>
									</b-card>
								</b-col>
							</b-row>
						</div>
					</b-container>
				</b-form>
			</b-container>
		</b-card>
	</div>
</template>

<script>
// Component
import dispatchImporterTemplate from '@/assets/files/DispatchImporter.json';

// Util
import { DateUtil } from '@/utils/dateutil';
import { DispatchUtil } from '@/utils/dispatchUtil';
import { ImportUtil } from '@/utils/importUtil';

// API
import assetsDAO from '@/database/assets';
import dataImporterApi from '@/api/dataImporterApi';

// Others
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import config from '@/config/env-constants';

export default {
	components: {
		Loading,
	},
	data() {
		return {
			file: null,
			loadedTextFromFile: '',

			dispatch: null,

			companiesObj: {},
			storageLocationsObj: {},
			connectionsObj: {},
			transportationsObj: {},
			assetsObj: {},

			importOngoing: false,
			importDone: false,
			hasError: false,
			importResultLog: '',

			currUserId: this.$store.getters.loggedUser.id,
			jsonData: {},
			templateUrl:
				'data:text/json;charset=utf-8,' +
				encodeURIComponent(
					JSON.stringify(dispatchImporterTemplate, null, '\t')
				),
		};
	},
	computed: {
		importStatusDisplay() {
			let statusDisplay = '';

			if (this.importOngoing) {
				statusDisplay = 'Import In-Progress.';
			} else {
				if (this.hasError) {
					statusDisplay = this.importResultLog;
				} else {
					statusDisplay = 'Import Successful! \n' + this.importResultLog;
				}
			}
			return statusDisplay;
		},
	},
	methods: {
		loadTextFromFile(ev) {
			const file = ev.target.files[0];
			if (this.validateFileAfterBrowse(file)) {
				const reader = new FileReader();
				reader.onload = (e) => {
					// load the content of the file on a string variable
					this.loadedTextFromFile = e.target.result;
					this.jsonData = JSON.parse(this.loadedTextFromFile);
					this.jsonData = ImportUtil.trimWhiteSpaces(this.jsonData);
				};
				reader.readAsText(file);
			}
		},
		validateFileAfterBrowse(file) {
			let isValid = true;

			if (!file) {
				this.$toaster.warning(
					'Please select a valid dispatch form to proceed.'
				);
				isValid = false;

				this.loadedTextFromFile = '';
			} else if (file.type != 'application/json') {
				this.$toaster.error(
					'Invalid File Type: Please import a valid dispatch form in JSON format.'
				);
				isValid = false;

				this.loadedTextFromFile = '';
			}
			return isValid;
		},

		getConnectionsObj(connections) {
			let connectionsObj = {};
			let connectionsArr = Object.values(connections);
			connectionsArr.forEach((connection) => {
				let sourceCompany = connection.company.name;
				let destinationCompany = connection.connectedCompany.name;

				if (!connectionsObj[sourceCompany]) {
					connectionsObj[sourceCompany] = [];
				}
				connectionsObj[sourceCompany].push(destinationCompany);

				if (connection.connectedCompany.isActive === 'false') {
					if (!connectionsObj[destinationCompany]) {
						connectionsObj[destinationCompany] = [];
					}
					connectionsObj[destinationCompany].push(sourceCompany);
				}
			});
			return connectionsObj;
		},
		getCollectionByKey(values, key) {
			return _.keyBy(Object.values(values), key);
		},

		async importFile() {
			// Prepare Fields for Validation
			let companies = this.$store.getters.companies;
			let connectedCompanies = this.$store.getters.connectedCompanies;
			this.companiesObj = this.getCollectionByKey(
				{ ...companies, ...connectedCompanies },
				'name'
			);

			let storageLocations = this.$store.getters.storageLocations;
			let connectedStorageLocations =
				this.$store.getters.connectedStorageLocations;
			this.storageLocationsObj = this.getCollectionByKey(
				{ ...storageLocations, ...connectedStorageLocations },
				'name'
			);

			this.connectionsObj = this.getConnectionsObj(
				this.$store.getters.connections
			);

			this.transportationsObj = this.getCollectionByKey(
				this.$store.getters.transportations,
				'plateNo'
			);

			let json = JSON.parse(this.loadedTextFromFile);
			let assetsObjResult = await assetsDAO.getAssetsByCode(json.assets);
			this.assetsObj = assetsObjResult[0];

			let param = {
				companiesObj: this.companiesObj,
				storageLocationsObj: this.storageLocationsObj,
				connectionsObj: this.connectionsObj,
				assetsObj: this.assetsObj,
			};

			let vm = this;
			if (DispatchUtil.validate(json, param, vm)) {
				this.buildDispatchForm(json);
				this.saveOnDatabase();
			}
		},

		buildDispatchForm(json) {
			this.dispatch = {};

			this.updatePrimaryDetails(this.dispatch, json);
			this.updateAssetRelatedFields(this.dispatch, json);
			this.updateBooleanStateFields(this.dispatch, json);
			this.updateOtherDispatchFields(this.dispatch, json);
			this.updateTimestamps(this.dispatch, json);
		},

		updatePrimaryDetails(dispatch, json) {
			let currTimeStamp = DateUtil.getCurrentTimestamp();
			let displayTimeStamp = DateUtil.getFormattedDateWithTime(currTimeStamp);

			dispatch.dispatchId = json.dispatchId
				? json.dispatchId
				: 'DS' + currTimeStamp;

			dispatch.status = json.status;
			dispatch.source = this.getDispatchSource(json);
			dispatch.destination = this.getDispatchDestination(json);
			dispatch.sourceScanners = [];
			dispatch.destinationScanners = [];

			dispatch.notes = json.notes ? json.notes + '; ' : '';
			dispatch.notes += 'Created from Dispatch Importer at ' + displayTimeStamp;

			dispatch.transportation = this.getTransportation(json);
			dispatch.driver = this.getDriver(json);
		},
		getDispatchSource(json) {
			let company = json.source.company;
			let companyObj = this.companiesObj[company];

			let storageLocation = json.source.storageLocation;
			let locObj = this.storageLocationsObj[storageLocation];

			return {
				company: companyObj.name,
				companyId: companyObj.id,
				storageLocation: locObj.name,
				storageLocationId: locObj.id,
				geoaddress: locObj.geoaddress,
			};
		},
		getDispatchDestination(json) {
			let company = json.destination.company;
			let companyObj = this.companiesObj[company];

			let storageLocation = json.destination.storageLocation;
			let locObj = this.storageLocationsObj[storageLocation];

			return {
				company: companyObj.name,
				companyId: companyObj.id,
				storageLocation: locObj.name,
				storageLocationId: locObj.id,
				geoaddress: locObj.geoaddress,
			};
		},
		getTransportation(json) {
			let company = json.transportation.company;
			return {
				plateNo: json.transportation.plateNo,
				company: company,
				companyId: this.companiesObj[company].id
			};
		},
		getDriver(json) {
			return {
				name: json.driver.name,
				licenseUrl: json.driver.licenseUrl,
				assistants: json.driver.assistants,
			}
		},

		updateAssetRelatedFields(dispatch, json) {
			dispatch.damagedAssets = [];
			dispatch.addedExpectedAssets = [];
			dispatch.invalidAssets = [];
			dispatch.assetsToDelete = [];
			dispatch.actualAssetsToDelete = [];
			dispatch.inputAssetLog = config.dispatchInputAssetLogDefaultValue;

			dispatch.assets = json.assets;
			dispatch.inputAssetLog['dispatch'].auto = json.assets;

			if (dispatch.status === 'Received') {
				dispatch.actualAssets = json.assets;
				dispatch.inputAssetLog['receipt'].auto = json.assets;
			}
		},

		updateBooleanStateFields(dispatch, json) {
			dispatch.apply1Up1Down = false;
			dispatch.doneAutoScan = false;
			dispatch.donePushPull = false;
			dispatch.isOffline = false;

			let sourceObj = this.companiesObj[json.source.company];
			let destinationObj = this.companiesObj[json.destination.company];

			dispatch.fromInactiveNode = DispatchUtil.isInactiveCompany(sourceObj);
			dispatch.toInactiveNode = DispatchUtil.isInactiveCompany(destinationObj);
		},

		updateOtherDispatchFields(dispatch, json) {
			dispatch.proofOfReceipt = {};
			dispatch.creationSource = '';
			dispatch.operation = '';
			dispatch.newTransportationToAdd = this.getNewTransportationToAdd(json);
			dispatch.prevDispatchFromPushPull = null;
			dispatch.validationWarnings = [];
		},
		getNewTransportationToAdd(json) {
			let newTransportationToAdd = null;

			// Build Transportation Form if still not existing
			if (!this.transportationsObj[json.transportation.plateNo]) {
				let company = json.transportation.company;
				newTransportationToAdd = {
					plateNo: json.transportation.plateNo,
					description: '',
					company: company,
					companyId: '',
					isActive: 'true',
					createdBy: this.currUserId,
					dateCreated: DateUtil.getCurrentTimestamp(),
					updatedBy: this.currUserId,
					dateUpdated: DateUtil.getCurrentTimestamp(),
				};

				let companyObj = this.companiesObj[company];
				if (companyObj) {
					newTransportationToAdd.companyId = this.companiesObj[company].id;
				}
			}

			return newTransportationToAdd;
		},

		updateTimestamps(dispatch, json) {
			let dateDeployed = DateUtil.getTimestamp(json.dateDeployed);

			dispatch.dateCreated = dateDeployed;
			dispatch.createdBy = this.currUserId;

			dispatch.dateUpdated = dateDeployed;
			dispatch.updatedBy = this.currUserId;

			dispatch.dateDeployed = dateDeployed;
			dispatch.deployedBy = this.currUserId;

			dispatch.dateCancelled = null;
			dispatch.cancelledBy = '';

			if (dispatch.status === 'Received') {
				let dateReceived = DateUtil.getTimestamp(json.dateReceived);
				dispatch.dateReceived = dateReceived;
				dispatch.receivedBy = this.currUserId;
			} else {
				dispatch.dateReceived = null;
				dispatch.receivedBy = '';
			}
		},

		async saveOnDatabase() {
			this.importOngoing = true;
			this.importDone = false;

			// Continue with import
			let param = {
				currUserId: this.currUserId,
				currTimeStamp: DateUtil.getCurrentTimestamp(),
				dispatch: this.dispatch,
			};

			try {
				let { data } = await dataImporterApi.importDispatch(param);

				this.hasError = !data.isSuccess;
				this.importResultLog = data.message;
				this.importDone = true;
				this.importOngoing = false;

				// update store
				let dispatchesObj = data.dispatches;
				this.$store.dispatch('updateAllDispatches', dispatchesObj);
			} catch (error) {
				this.hasError = true;
				this.importResultLog = error;
				this.importDone = true;
				this.importOngoing = false;
			}
		},
		startAnotherImport() {
			this.importDone = false;
			this.importOngoing = false;
			// reset form fields
			this.$refs.fileinput.reset();
			this.file = null;
			this.loadedTextFromFile = '';
		},
	},
};
</script>

<style scoped></style>
