import { render, staticRenderFns } from "./DispatchImporter.vue?vue&type=template&id=6999e2fb&scoped=true&"
import script from "./DispatchImporter.vue?vue&type=script&lang=js&"
export * from "./DispatchImporter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6999e2fb",
  null
  
)

export default component.exports